<template>
  <v-card>
    <v-card-title>
      <span>{{$t('t.Logo')}}</span>
      <input
        id="selectLogo"
        @change="setLogo"
        type="file"
        hidden
      >
      <v-btn
        class="ml-4"
        fab
        x-small
        @click.stop="selectLogo()"
      >
        <v-icon ref="check">{{$icon('i.LiveEdition')}}</v-icon>
      </v-btn>
      <v-btn
        v-if="dataLogoImage"
        class="ml-4"
        fab
        x-small
        @click.stop="removeLogo()"
      >
        <v-icon ref="check">{{$icon('i.Delete')}}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="d-flex align-center justify-center">

      <v-skeleton-loader
        height=250
        type="image"
        v-if="dataLoading && dataLogoFileId"
      />
      <v-img
        v-else
        contain
        max-width="250"
        max-height="250"
        :src="dataLogoImage"
      />
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  components: {
  },
  data () {
    return {
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataLogoFileId: null,
      dataLastLogoFileId: null,
      dataLogoFile: null,
      dataLogoImage: null,
      dataLoading: false
    }
  },
  computed: {
  },
  methods: {
    emitDocument () {
      if (!this.lodash.isEqual(this.value, this.dataLogoFileId)) {
        this.$emit('input', this.lodash.cloneDeep(this.dataLogoFileId))
      }
    },
    selectLogo: function () {
      document.getElementById('selectLogo').click()
    },
    setLogo (e) {
      this.dataLogoFile = e.target.files[0]
      const f = new File([this.dataLogoFile], this.dataLogoFile.name, { type: this.dataLogoFile.type })
      const reader = new FileReader()
      reader.onload = (e) => {
        this.dataLogoImage = e.target.result
      }
      reader.readAsDataURL(f)

      this.dataLastLogoFileId = null
      this.$emit('input', {
        logoFileId: null,
        logoFile: new File([this.dataLogoFile], this.dataLogoFile.name, { type: this.dataLogoFile.type })
      })
    },
    removeLogo () {
      this.dataLogoFile = null
      this.dataLastLogoFileId = null
      this.$emit('input', {
        logoFileId: null,
        logoFile: null
      })
    },
    async getLogo () {
      if (!this.dataLogoFileId && !this.dataLogoFile) {
        this.dataLogoImage = null
        return
      }

      if (this.dataLastLogoFileId === this.dataLogoFileId) {
        return
      }

      if (this.dataLastLogoFileId !== this.dataLogoFileId) {
        this.dataLoading = true

        const apiUrl = '/core/v6/files'

        const result = await this.$http().get(`${apiUrl}/${this.dataLogoFileId}`)
        this.dataLogoImage = 'data:image/jpeg;base64,' + btoa(
          new Uint8Array(result?.data?.content)
            .reduce((data, byte) => data + String.fromCharCode(byte), ''))

        this.dataLastLogoFileId = this.dataLogoFileId
        this.dataLoading = false
      }
    }

  },
  props: {
    value: Object
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataLogoFileId = v?.logoFileId
        this.dataLogoFile = v?.logoFile
        this.getLogo()
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
